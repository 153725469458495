import React, { Fragment } from "react";
import { Menu, Search } from "react-feather";
import { Link } from "react-router-dom";
import { Nav, Navbar, InputGroup, Form, Image } from "react-bootstrap";
import "simplebar/dist/simplebar.min.css";
import ConferencesDropdown from "./ConferencesDropdown";
import UserSettingsDropdown from "./UserSettingsDropdown";
//import NotificationsDropdown from "./NotificationsDropdown";
import Logo from "../../assets/images/brand/logo/logo_gray_horizontal.png";
import PropTypes from "prop-types";

const HeaderDefault = (props) => {
  return (
    <Fragment>
      <Navbar expanded="lg" className="navbar-default">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center w-50">
            <Link
              id="nav-toggle"
              to="#"
              onClick={() =>
                props.data.SidebarToggleMenu(!props.data.isMenuOpened)
              }
            >
              <Menu size="18px" />
            </Link>
            <div className="ms-lg-3 d-block">
              <Navbar.Brand as={Link} to="/">
                <Image
                  src={Logo}
                  alt=""
                  style={{ height: "45px" }}
                  className="px-3"
                />
              </Navbar.Brand>
            </div>
            <div className="ms-lg-3 d-none d-md-none d-lg-block w-100">
              <Form className=" d-flex align-items-center ">
                <InputGroup
                  className="input-group-merge search-bar w-75"
                  bsPrefix="group-of-input"
                >
                  <InputGroup.Text className="ps-2 pe-1 mx-2 my-1 h-40 position-absolute search-icon border-0">
                    <Search size="12px" className="text-secondary" />
                  </InputGroup.Text>
                  <Form.Control
                    type="search"
                    className="form-control form-control-sm ps-6"
                    placeholder="Search Entire Dashboard"
                  />
                </InputGroup>
              </Form>
            </div>
          </div>

          <Nav className="navbar-nav navbar-right-wrap ms-auto d-flex align-items-center nav-top-wrap">
            {/* Notifications component  */}
            {/* <NotificationsDropdown /> */}

            {/* List of Conferences user belongs to */}
            <ConferencesDropdown currentUser={props.currentUser} />
            {/* User Settings */}
            <UserSettingsDropdown currentUser={props.currentUser} />
          </Nav>
        </div>
      </Navbar>
    </Fragment>
  );
};

HeaderDefault.propTypes = {
  data: PropTypes.shape({
    SidebarToggleMenu: PropTypes.func,
    isMenuOpened: PropTypes.bool,
  }),
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    avatarUrl: PropTypes.string,
    email: PropTypes.string,
    conferenceId: PropTypes.number,
  }),
};

export default HeaderDefault;
