import { v4 as uuid } from "uuid";

const NavbarDefault = [
  {
    id: uuid(),
    menuitem: "Dashboard",
    header: true,
    link: "#",
    roles: [
      "Admin",
      "Assigner",
      "Official",
      "Team Personnel",
      "Game Day Personnel",
      "Supervisor",
      "Grader",
      "Candidate",
    ],
    children: [
      {
        id: uuid(),
        menuitem: "Dashboard",
        link: "/dashboard",
        roles: [
          "Admin",
          "Assigner",
          "Official",
          "Team Personnel",
          "Game Day Personnel",
          "Supervisor",
          "Grader",
          "Candidate",
        ],
      },
    ],
  },

  {
    id: uuid(),
    menuitem: "Games",
    link: "#",
    roles: ["Admin", "Assigner", "Official"],
    children: [
      {
        id: uuid(),

        menuitem: "All Games",
        link: "/games",
        roles: ["Admin", "Assigner", "Official"],
      },
      {
        id: uuid(),

        menuitem: "Game Reports",
        link: "/games/reports",
        roles: ["Admin", "Assigner", "Official"],
      },
    ],
  },
  {
    id: uuid(),
    menuitem: "Training",
    link: "#",
    roles: [
      "Admin",
      "Assigner",
      "Official",
      "Team Personnel",
      "Game Day Personnel",
      "Supervisor",
      "Grader",
      "Candidate",
    ],
    children: [
      {
        id: uuid(),
        menuitem: "Announcements",
        link: "/announcements",
        roles: [
          "Admin",
          "Assigner",
          "Official",
          "Team Personnel",
          "Game Day Personnel",
          "Supervisor",
          "Grader",
          "Candidate",
        ],
      },
      {
        id: uuid(),
        menuitem: "Videos",
        link: "/trainingvideo/details",
        roles: [
          "Admin",
          "Assigner",
          "Official",
          "Team Personnel",
          "Game Day Personnel",
          "Supervisor",
          "Grader",
          "Candidate",
        ],
      },
      {
        id: uuid(),
        menuitem: "Rules",
        link: "/rules",
        roles: [
          "Admin",
          "Assigner",
          "Official",
          "Team Personnel",
          "Game Day Personnel",
          "Supervisor",
          "Grader",
          "Candidate",
        ],
      },
      {
        id: uuid(),
        menuitem: "Podcasts",
        link: "/podcasts",
        roles: [
          "Admin",
          "Assigner",
          "Official",
          "Team Personnel",
          "Game Day Personnel",
          "Supervisor",
          "Grader",
          "Candidate",
        ],
      },
    ],
  },
  {
    id: uuid(),
    menuitem: "Support",
    link: "",
    roles: [
      "Admin",
      "Assigner",
      "Official",
      "Team Personnel",
      "Game Day Personnel",
      "Supervisor",
      "Grader",
      "Candidate",
    ],
    children: [
      // {
      //   id: uuid(),
      //   divider: true,
      // },
      {
        id: uuid(),
        menuitem: "About",
        roles: ["Public"],
        link: "/aboutusmemberlist",
      },
      {
        id: uuid(),
        menuitem: "Help Center",
        link: "#",
        badge: "New",
        badgecolor: "primary",
        roles: ["Public"],
        children: [
          {
            id: uuid(),
            menuitem: "Help Center",
            link: "/helpandsupport",
          },
          {
            id: uuid(),
            menuitem: "FAQ's",
            link: "/helpandsupport",
          },
          {
            id: uuid(),
            menuitem: "Support",
            link: "/contactus",
          },
        ],
      },
      {
        id: uuid(),
        menuitem: "Pricing",
        link: "/subscriptions",
        roles: ["Public"],
      },
      // {
      //   id: uuid(),
      //   menuitem: "Compare Plan",
      //   link: "/marketing/pages/compare-plan/",
      //   roles: ["Public"],
      // },
      {
        id: uuid(),
        menuitem: "Contact",
        link: "/contactus",
        roles: ["Public"],
      },
    ],
  },
];

export default NavbarDefault;
