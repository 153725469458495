import React, { Fragment, useEffect } from "react";
import NavbarDefault from "layouts/marketing/navbars/NavbarDefault";
import FooterWithLinks from "layouts/marketing/footers/FooterWithLinks";
import PropTypes from "prop-types";
import sabioDebug from "sabio-debug";

const _logger = sabioDebug.extend("Default Layout");

const DefaultLayout = (props) => {
  useEffect(() => {
    document.body.style.backgroundColor = "#f5f5f5";
  });

  _logger(props, "value of props");

  return (
    <Fragment>
      <NavbarDefault {...props} />
      {props.children}
      <FooterWithLinks currentUser={props.currentUser} />
    </Fragment>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.element,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    avatarUrl: PropTypes.string,
    email: PropTypes.string,
    tenantId: PropTypes.number,
    isLoggedIn: PropTypes.bool,
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default DefaultLayout;
