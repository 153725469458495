import React, { useState, useEffect } from "react";
import LanguageContext from "./LanguageContext";
import getByLanguageId from "../../services/pageTranslationsDisplayService";
import PropTypes from "prop-types";
import debug from "sabio-debug";
import { useLocation } from "react-router-dom";

const _logger = debug.extend("LanguageProvider");

const LanguageProvider = ({ children }) => {
  const [language, setLanguageState] = useState(3);
  const [phrases, setPhrases] = useState([]);

  const location = useLocation();
  const currentPage = location.pathname.replace(/^\/(es\/)?/, "");

  const updatePhrases = (response) => {
    let filteredPhrases = [];
    _logger("response.items", response.items);
    response.items.forEach((item) => {
      _logger("currentpage", currentPage);
      if (item.pageTranslation.link.toLowerCase() === currentPage && item.pageTranslation.language.id === language) {
        item.pageSections.forEach((section) => {
          if (section.pageSectionKeys) {
            section.pageSectionKeys.forEach((key) => {
              key.pageSectionContents.forEach((content) => {
                if (content.languageId === language) {
                  _logger("content", content);
                  filteredPhrases.push({
                    keyName: key.keyName,
                    text: content.text,
                    languageId: content.languageId,
                  });
                }
              });
            });
          }
        });
      }
    });

    setPhrases(filteredPhrases);
  };

  const onSuccess = (response) => {
    _logger("getLanguageID onSuccess response:", response);

    if (!response || !response.items) {
      _logger("Unexpected response");
      return;
    }

    updatePhrases(response);
  };

  const onError = (error) => {
    _logger("Failed Translation results", error);
  };

  useEffect(() => {
    _logger(`Current page (linkName): ${currentPage}`);
    if (language === 3) {
      setPhrases([]);
    } else {
    }
    getByLanguageId(currentPage, language).then(onSuccess).catch(onError);
    _logger(`Language set to: ${language}`);
  }, [language, currentPage]);

  const setLanguage = (languageId) => {
    _logger(`Setting language: ${languageId}`);
    setLanguageState(languageId);
  };

  const languageSet = {
    language,
    setLanguage,
    phrases,
  };

  return <LanguageContext.Provider value={languageSet}>{children}</LanguageContext.Provider>;
};

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LanguageProvider;
