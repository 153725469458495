import { v4 as uuid } from "uuid";
/**
 *  All Dashboard Routes
 *
 *  Understanding name/value pairs for Dashboard routes
 *
 *  Applicable for main/root/level 1 routes
 *  icon 		: String - It's only for main menu or you can consider 1st level menu item to specify icon name.
 * 				: Object - Icon as an object added from v1.4.0.
 *
 *  Applicable for main/root/level 1 and subitems routes
 * 	id 			: Number - You can use uuid() as value to generate unique ID using uuid library, you can also assign constant unique ID for react dynamic objects.
 *  title 		: String - If menu contains childern use title to provide main menu name.
 *  badge 		: String - (Optional - Default - '') If you specify badge value it will be displayed beside the menu title or menu item.
 * 	badgecolor 	: String - (Optional - Default - 'primary' ) - Used to specify badge background color.
 *
 *  Applicable for subitems / children items routes
 *  name 		: String - If it's menu item in which you are specifiying link, use name ( don't use title for that )
 *  children	: Array - Use to specify submenu items
 *
 *  Used to segrigate menu groups
 *  grouptitle : Boolean - (Optional - Default - false ) If you want to group menu items you can use grouptitle = true,
 *  ( Use title : value to specify group title  e.g. COMPONENTS , DOCUMENTATION that we did here. )
 *
 */

export const DashboardMenu = [
  {
    id: uuid(),
    title: "Dashboard",
    icon: "home",
    roles: [
      "Admin",
      "Assigner",
      "Official",
      "Game Day Personnel",
      "Team Personnel",
      "Supervisor",
      "Grader",
      "Candidate",
    ],
    children: [
      {
        id: uuid(),
        link: "/dashboard",
        name: "Dashboard",
        roles: [
          "Admin",
          "Assigner",
          "Official",
          "Game Day Personnel",
          "Team Personnel",
          "Supervisor",
          "Grader",
          "Candidate",
          "Team Admin",
        ],
      },
      {
        id: uuid(),
        link: "/filemanager",
        name: "File Manager",
        roles: ["Admin"],
      },
      // {
      //   id: uuid(),
      //   link: "/dashboard/messages",
      //   name: "Messages",
      //   roles: [
      //     "Admin",
      //     "Assigner",
      //     "Official",
      //     "Game Day Personnel",
      //     "Team Personnel",
      //     "Supervisor",
      //     "Grader",
      //     "Candidate",
      //     "Team Admin",
      //   ],
      // },
    ],
  },
  {
    id: uuid(),
    title: "Users",
    icon: "user",
    roles: ["Assigner", "Admin"],
    children: [
      {
        id: uuid(),
        link: "/admin/designatepersonnel",
        name: "Designate Personnel",
        roles: ["Assigner", "Admin"],
      },
      {
        id: uuid(),
        link: "/admin/useradminview",
        name: "All Users",
        roles: ["Admin"],
      },
      {
        id: uuid(),
        link: "/newsletter/admin",
        name: "Newsletters",
        roles: ["Admin"],
      },
    ],
  },

  {
    id: uuid(),
    title: "CONFERENCE",
    grouptitle: true,
    roles: [
      "Admin",
      "Assigner",
      "Supervisor",
      "Grader",
      "Official",
      "Team Admin",
    ],
  },
  {
    id: uuid(),
    title: "Games",
    icon: "book",
    roles: ["Admin", "Assigner", "Supervisor", "Grader", "Official"],
    children: [
      {
        id: uuid(),
        link: "/games",
        name: "All Games",
        roles: ["Admin", "Assigner", "Supervisor", "Grader"],
      },
      {
        id: uuid(),
        link: "/games/new",
        name: "Add Game ",
        roles: ["Admin", "Assigner", "Supervisor", "Grader"],
      },
      {
        id: uuid(),
        link: "/assignments/current",
        name: "Accept/Decline Games",
        roles: ["Admin", "Official"],
      },
      {
        id: uuid(),
        link: "/assignments/new",
        name: "Assign Games",
        roles: ["Admin", "Assigner"],
      },
    ],
  },

  {
    id: uuid(),
    title: "Officials",
    icon: "book",
    roles: [
      "Admin",
      "Assigner",
      "Supervisor",
      "Grader",
      "Official",
      "Team Personnel",
      ,
      "Team Admin",
    ],
    children: [
      {
        id: uuid(),
        link: "/officials",
        name: "All Officials",
        roles: [
          "Admin",
          "Assigner",
          "Supervisor",
          "Grader",
          "Official",
          "Team Personnel",
          ,
          "Team Admin",
        ],
      },
      {
        id: uuid(),
        link: "/officials/conflicts/table",
        name: "Conflicts",
        roles: ["Admin", "Assigner"],
      },
      {
        id: uuid(),
        link: "/admin/designatepersonnel",
        name: "Invite Official",
        roles: ["Admin", "Assigner", "Team Admin"],
      },
      {
        id: uuid(),
        link: "/assignments/team/6",
        name: "Add Game Day Assignments",
        roles: ["Admin", "Assigner", "Supervisor", "Grader", "Team Admin"],
      },
    ],
  },
  {
    id: uuid(),
    title: "Crews",
    icon: "book",
    roles: ["Admin", "Assigner", "Supervisor", "Grader"],
    children: [
      {
        id: uuid(),
        link: "/crews",
        name: "Crews Table",
        roles: ["Admin", "Assigner"],
      },
      {
        id: uuid(),
        link: "/crews/builder",
        name: "Crews Builder",
        roles: ["Admin", "Assigner"],
      },
    ],
  },

  {
    id: uuid(),
    title: "Teams",
    icon: "book",
    roles: [
      "Admin",
      "Assigner",
      "Official",
      "Game Day Personnel",
      "Team Personnel",
      "Supervisor",
      "Grader",
      "Candidate",
      "Team Admin",
    ],
    children: [
      {
        id: uuid(),
        link: "/teams",
        name: "All Teams",
        roles: [
          "Admin",
          "Assigner",
          "Official",
          "Game Day Personnel",
          "Team Personnel",
          "Supervisor",
          "Grader",
          "Candidate",
          "Team Admin",
        ],
      },
      {
        id: uuid(),
        link: "/teams/new",
        name: "Add Team",
        roles: ["Admin", "Assigner"],
      },

      {
        id: uuid(),
        link: "/venues",
        name: "Venues",
        roles: [
          "Admin",
          "Assigner",
          "Official",
          "Game Day Personnel",
          "Team Personnel",
          "Supervisor",
          "Grader",
          "Candidate",
        ],
      },
    ],
  },

  {
    id: uuid(),
    title: "Candidates",
    icon: "book",
    roles: ["Admin", "Assigner", "Supervisor", "Grader"],
    children: [
      {
        id: uuid(),
        link: "/candidates",
        name: "All Candidates",
        roles: ["Admin", "Assigner", "Supervisor", "Grader"],
      },
    ],
  },

  {
    id: uuid(),
    title: "Conferences",
    icon: "file",
    roles: ["Admin"],
    children: [
      {
        id: uuid(),
        link: "/conferences",
        name: "All Conferences",
        roles: ["Admin"],
      },
      {
        id: uuid(),
        link: "/conferences/new",
        name: "Create a Conference",
        roles: ["Admin"],
      },
    ],
  },
  {
    id: uuid(),
    title: "Seasons",
    icon: "file",
    roles: ["Admin"],
    children: [
      {
        id: uuid(),
        link: "/seasons",
        name: "Seasons By Conference",
        roles: ["Admin"],
      },
      {
        id: uuid(),
        link: "/seasons/new",
        name: "Create A Season",
        roles: ["Admin"],
      },
    ],
  },
  {
    id: uuid(),
    title: "EVALUATIONS",
    grouptitle: true,
    roles: ["Admin", "Assigner", "Supervisor", "Grader", "Official"],
  },
  {
    id: uuid(),
    title: "Reports",
    icon: "book",
    roles: ["Admin", "Assigner", "Supervisor", "Grader", "Official"],
    children: [
      {
        id: uuid(),
        link: "/games/reports",
        name: "Game Reports",
        roles: ["Admin", "Assigner", "Supervisor", "Grader", "Official"],
      },
      {
        id: uuid(),
        link: "/games/reports/new",
        name: "New Game Report",
        roles: ["Admin", "Assigner", "Supervisor", "Grader", "Official"],
      },
      {
        id: uuid(),
        link: "/games/reports/replay",
        name: "Replay Reports",
        roles: ["Admin", "Assigner", "Supervisor", "Grader", "Official"],
      },
    ],
  },
  {
    id: uuid(),
    title: "Grades",
    icon: "book",
    roles: ["Admin", "Assigner", "Supervisor", "Grader"],
    children: [
      {
        id: uuid(),
        link: "/analytics/gradefoul",
        name: "Grade and Foul Analytics",
        roles: ["Admin", "Assigner", "Supervisor", "Grader"],
      },
    ],
  },
  {
    id: uuid(),
    title: "Certifications",
    icon: "book",
    roles: ["Admin", "Assigner"],
    children: [
      {
        id: uuid(),
        link: "/certifications",
        name: "Certifications",
        roles: ["Admin", "Assigner"],
      },
      {
        id: uuid(),
        link: "/certifications/new",
        name: "Add Certification",
        roles: ["Admin", "Assigner"],
      },
    ],
  },
  {
    id: uuid(),
    title: "Announcements",
    icon: "book",
    roles: ["Admin", "Assigner", "Candidate", "Official"],
    children: [
      {
        id: uuid(),
        link: "/announcements",
        name: "Announcements",
        roles: [
          "Admin",
          "Assigner",
          "Official",
          "Game Day Personnel",
          "Team Personnel",
          "Supervisor",
          "Grader",
          "Candidate",
          "Team Admin",
        ],
      },
      {
        id: uuid(),
        link: "/announcements/new",
        name: "Add Announcement",
        roles: ["Admin", "Assigner"],
      },
    ],
  },
  {
    id: uuid(),
    title: "Training",
    grouptitle: true,
    roles: ["Admin", "Assigner", "Supervisor", "Grader", "Official"],
  },
  // {
  //   id: uuid(),
  //   title: "Tests",
  //   icon: "book",
  //   children: [
  //     {
  //       id: uuid(),
  //       link: "/tests",
  //       name: "All Tests",
  //       roles: ["Admin", "Assigner", "Supervisor", "Grader"],
  //     },
  //     { id: uuid(), link: "/test/results", name: "Test Results" },
  //   ],
  //   icon: "message-square",
  //   link: "/tests",
  //   badgecolor: "success",
  //   roles: ["Admin", "Assigner", "Supervisor", "Grader", "Official"],
  // },
  {
    id: uuid(),
    title: "Videos",
    icon: "help-circle",
    link: "/marketing/help-center/",
    roles: [
      "Admin",
      "Assigner",
      "Supervisor",
      "Grader",
      "Candidate",
      "Official",
    ],
    children: [
      { id: uuid(), link: "/videos/details", name: "View Videos" },
      {
        id: uuid(),
        link: "/videos/add",
        name: "Add Training Video",
        roles: ["Admin", "Assigner", "Supervisor", "Grader"],
      },
    ],
  },
  // {
  //   id: uuid(),
  //   title: "Meetings",
  //   icon: "book",
  //   roles: ["Admin", "Assigner", "Supervisor", "Grader", "Official"],
  //   children: [
  //     {
  //       id: uuid(),
  //       link: "/meetings/zoom",
  //       name: "Create Meeting",
  //       roles: ["Admin", "Assigner", "Supervisor", "Grader", "Official"],
  //     },
  //     {
  //       id: uuid(),
  //       link: "/meetings/userMeetings",
  //       name: "Your Meetings",
  //       roles: ["Admin", "Assigner", "Supervisor", "Grader", "Official"],
  //     },
  //   ],
  // },

  {
    id: uuid(),
    title: "Resources",
    grouptitle: true,
  },
  {
    id: uuid(),
    title: "All Resources",
    icon: "clipboard",
    link: "/resources",
  },

  {
    id: uuid(),
    title: "Rules",
    icon: "clipboard",
    link: "/resources?categoryId=1",
  },
  {
    id: uuid(),
    title: "Mechanics",
    icon: "clipboard",
    link: "/resources?categoryId=2",
  },

  {
    id: uuid(),
    title: "PAYMENTS",
    grouptitle: true,
    roles: ["Admin", "Assigner"],
  },
  // {
  //   id: uuid(),
  //   title: "Charges",
  //   icon: "clipboard",
  //   link: "/stripe/chargeform",
  //   roles: ["Admin"],
  // },
  // {
  //   id: uuid(),
  //   title: "Transfer",
  //   icon: "clipboard",
  //   link: "/stripe/transfer",
  //   roles: ["Admin"],
  // },
  {
    id: uuid(),
    title: "Site Settings",
    grouptitle: true,
    roles: ["Admin"],
  },
  {
    id: uuid(),
    title: "Site Settings",
    icon: "settings",
    roles: ["Admin"],
    children: [
      { id: uuid(), link: "/settings/general", name: "General", roles: [""] },
      { id: uuid(), link: "/settings/google", name: "Google", roles: [""] },
      { id: uuid(), link: "/settings/social", name: "Social", roles: [""] },
      {
        id: uuid(),
        link: "/settings/social-login",
        name: "Social Login",
        roles: [""],
      },
      { id: uuid(), link: "/settings/payment", name: "Payment", roles: [""] },
      { id: uuid(), link: "/settings/smtp-server", name: "SMPT", roles: [""] },
    ],
  },
  {
    id: uuid(),
    title: "Help Center",
    grouptitle: true,
  },
  {
    id: uuid(),
    title: "Documentation",
    icon: "clipboard",
    link: "/dashboard/documentation",
    title: "Help Center",
    icon: "help-circle",
    roles: ["Admin", "Assigner", "Official", "Supervisor", "Grader"],
    children: [
      {
        id: uuid(),
        link: "/admin/faqs",
        name: "FAQs",
        roles: ["Admin", "Assigner", "Official", "Supervisor", "Grader"],
      },
    ],
  },
];

export default DashboardMenu;
