import axios from "axios";
import {
  onGlobalError,
  onGlobalSuccess,
  API_HOST_PREFIX,
} from "./serviceHelpers";
const endpoint = `${API_HOST_PREFIX}/api/users`;

const registerUser = (payload) => {
  const config = {
    method: "post",
    url: `${endpoint}/register`,
    crossdomain: true,
    data: payload,
    headers: {
      "content-type": "application/json",
    },
  };

  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const loginUser = (payload) => {
  const config = {
    method: "post",
    url: `${endpoint}/login`,
    crossdomain: true,
    data: payload,
    headers: {
      "content-type": "application/json",
    },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const logoutUser = () => {
  const config = {
    method: "get",
    url: `${endpoint}/logout`,
    crossdomain: true,
    withCredentials: true,
    headers: {
      "content-type": "application/json",
    },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const getCurrentUser = () => {
  const config = {
    method: "get",
    url: `${endpoint}/isauth`,
    crossdomain: true,
    withCredentials: true,
    headers: {
      "content-type": "application/json",
    },
  };
  return axios(config);
};

const verifyEmail = (payload) => {
  const config = {
    method: "post",
    url: `${endpoint}/verifyemail`,
    data: payload,
    crossdomain: true,
    withCredentials: true,
    headers: {
      "content-type": "application/json",
    },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const forgetPassword = (email) => {
  const config = {
    method: "post",
    url: `${endpoint}/forgot`,
    data: email,
    crossdomain: true,
    headers: {
      "content-type": "application/json",
    },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const changePassword = (payload) => {
  const config = {
    method: "put",
    url: `${endpoint}/changepassword`,
    data: payload,
    crossdomain: true,
    heades: {
      "content-type": "application/json",
    },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const inviteUser = (payload) => {
  const config = {
    method: "post",
    url: `${endpoint}/invitation`,
    data: payload,
    crossdomain: true,
    headers: {
      "content-type": "application/json",
    },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const getRegistrationSettings = (token, email) => {
  const config = {
    method: "get",
    url: `${endpoint}/invitation?token=${token}&email=${email}`,
    crossdomain: true,
    headers: {
      "content-type": "application/json",
    },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const getRegistrationSettingsOnLogin = (email) => {
  const config = {
    method: "get",
    url: `${endpoint}/firstlogin?email=${email}`,
    crossdomain: true,
    headers: {
      "content-type": "application/json",
    },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const getAllUser = (index) => {
  const config = {
    method: "GET",
    url: `${endpoint}/admin/view/all?pageIndex=${index}&pageSize=10`,
    crossdomain: true,
    withCredentials: true,
    header: { "Content-Type": "application/json" },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const searchAdminView = (index, size, query) => {
  const config = {
    method: "GET",
    url:
      `${endpoint}/admin/view/search?pageIndex=${index}&pageSize=` +
      size +
      "&query=" +
      query,
    crossdomain: true,
    withCredentials: true,
    header: { "Content-Type": "application/json" },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const updateStatus = (id, inputId) => {
  const config = {
    method: "PUT",
    url: `${endpoint}/update?Id=${id}&StatusId=${inputId}`,
    crossdomain: true,
    withCredentials: true,
    header: { "Content-Type": "application/json" },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const deleteRegistrationSettings = () => {
  const config = {
    method: "delete",
    url: `${endpoint}/invitation`,
    crossdomain: true,
    headers: {
      "content-type": "application/json",
    },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const searchUsers = (query, pageIndex, pageSize) => {
  const config = {
    method: "get",
    url: `${endpoint}/search?query=${query}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
    crossdomain: true,
    headers: {
      "content-type": "application/json",
    },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const selectUser = (id) => {
  const config = {
    method: "get",
    url: `${endpoint}/${id}`,
    crossdomain: true,
    headers: {
      "content-type": "application/json",
    },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const setConference = (conferenceId) => {
  const config = {
    method: "put",
    url: `${endpoint}/conference?conferenceId=${conferenceId}`,
    headers: {
      "content-type": "application/json",
    },
    crossdomain: true,
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const getConferencesByUserId = (id) => {
  const config = {
    method: "get",
    url: `${endpoint}/conferences?id=${id}`,
    headers: {
      "content-type": "application/json",
    },
    crossdomain: true,
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const uploadAvatar = (payload) => {
  const config = {
    method: "post",
    url: `${endpoint}/upload/avatar`,
    headers: {
      "content-type": "multipart/form-data",
    },
    data: payload,
    crossdomain: true,
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const getUserById = (id) => {
  const config = {
    method: "GET",
    url: `${endpoint}/${id}`,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const getCurrentProfile = () => {
  const config = {
    method: "GET",
    url: `${endpoint}/profile/current`,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const getByStatus = (index, size, statusId) => {
  const config = {
    method: "get",
    url: `${endpoint}/admin/view/status?pageIndex=${index}&pageSize=${size}&status=${statusId}`,
    crossdomain: true,
    headers: {
      "content-type": "application/json",
    },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const getUsersByConference = (id) => {
  const config = {
    method: "GET",
    url: `${endpoint}/byconference/${id}`,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const update = (payload, id) => {
  const config = {
    method: "PUT",
    url: `${endpoint}/${id}`,
    crossdomain: true,
    data: payload,
    headers: {
      "content-type": "application/json",
    },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const updateCurrent = (payload) => {
  const config = {
    method: "PUT",
    url: `${endpoint}`,
    crossdomain: true,
    data: payload,
    headers: {
      "content-type": "application/json",
    },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

const userService = {
  registerUser,
  update,
  updateCurrent,
  loginUser,
  logoutUser,
  getCurrentUser,
  getCurrentProfile,
  verifyEmail,
  forgetPassword,
  changePassword,
  getAllUser,
  searchAdminView,
  updateStatus,
  searchUsers,
  inviteUser,
  getRegistrationSettings,
  getRegistrationSettingsOnLogin,
  deleteRegistrationSettings,
  setConference,
  getConferencesByUserId,
  uploadAvatar,
  selectUser,
  getByStatus,
  getUserById,
  getUsersByConference,
};

export default userService;
