import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Nav, Dropdown, Image } from "react-bootstrap";
import PropTypes from "prop-types";
import userService from "services/userService";
import debug from "sabio-debug";

const ConferencesDropdown = (props) => {
  const [conferences, setConferences] = useState({
    listConferences: [],
    itemComponents: [],
    current: "",
  });
  const _logger = debug.extend("ConferencesDropdown");
  const navigate = useNavigate();

  useEffect(() => {
    if (props.currentUser?.isLoggedIn) {
      const conferences = [...props.currentUser.conferences];
      setConferences((prevState) => {
        const conf = { ...prevState };
        let indx = conferences?.findIndex(
          (cf) => cf.id === props.currentUser?.conferenceId
        );
        conf.current = conferences[indx];
        let listConferences = conferences;
        listConferences.splice(indx, 1);
        conf.itemComponents = listConferences?.map(mapConferences);
        _logger("Fricking Conferences", conferences);
        return conf;
      });
    }
  }, [props.currentUser]);

  const onClickHandler = (id) => {
    return () => {
      userService
        .setConference(id)
        .then(onChangeConferenceSuccess(id))
        .catch(onChangeConferenceError);
    };
  };

  const onChangeConferenceSuccess = (id) => {
    return () => {
      const pathName = window.location.pathname;
      navigate(pathName, {
        state: { conferencechange: id, pathName: pathName, login: false },
      });
    };
  };

  const onChangeConferenceError = () => {
    toastr.error("Conference Could Not Be Changed", "Conference Change Error");
    _logger("Conference could not be changed");
  };

  const mapConferences = (conference, idx) => {
    return (
      <Dropdown.Item
        onClick={onClickHandler(conference.id)}
        eventKey={idx}
        key={conference.id}
      >
        <Image
          alt={conference.name}
          src={conference.logo}
          className="avatar-sm me-1"
          style={{ objectFit: "contain" }}
        />
        {conference.name}
      </Dropdown.Item>
    );
  };

  return (
    <Dropdown as={Nav.Item}>
      <Dropdown.Toggle
        as={Nav.Link}
        bsPrefix="dt"
        className="rounded-circle border-bottom-0"
        id="dropdownUser"
      >
        <div className="avatar avatar-md">
          <Image
            alt={conferences?.current?.name}
            src={conferences?.current?.logo}
            style={{ objectFit: "contain" }}
          />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="dashboard-dropdown dropdown-menu-end mt-4 py-0"
        aria-labelledby="dropdownUser"
        align="end"
      >
        <Dropdown.Item className="mt-3">
          <div className="d-flex">
            <div className="avatar avatar-md">
              <Image
                alt={conferences?.current?.name}
                src={conferences?.current?.logo}
                style={{ objectFit: "contain" }}
              />
            </div>
            <div className="ms-3 lh-1">
              <h5 className="mb-1">{conferences?.current?.code}</h5>
              <p className="mb-0 text-muted">{conferences?.current?.name}</p>
            </div>
          </div>
        </Dropdown.Item>
        <Dropdown.Divider />
        {conferences.itemComponents}
        <Dropdown.Divider />
      </Dropdown.Menu>
    </Dropdown>
  );
};

ConferencesDropdown.propTypes = {
  currentUser: PropTypes.shape({
    conferenceId: PropTypes.number,
    id: PropTypes.number.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string),
    isLoggedIn: PropTypes.bool.isRequired,
    conferences: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        logo: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default ConferencesDropdown;
