export const essential = [
  {
    productIdMonthly: "prod_NhwEMTj0bsC9TI",
    productIdYearly: "prod_Nlzyq5ae0FmllB",
    name: "Essential Plan",
    description: `Access all
    <span class="text-dark fw-medium">essential courses, workshops, and mobile apps.</span>
    Renewed monthly or one time payment yearly.`,
    monthlyPriceValue: "999",
    yearlyPriceValue: "9999",
    monthlyPrice: 9.99,
    yearlyPrice: 99.99,
    featureHeading: "Great features like:",
    features: [
      { feature: "Online viewing" },
      { feature: `<span class="fw-bold text-dark">Online </span>projects` },
      {
        feature: `<span class="fw-bold text-dark">Some </span>storage`,
      },
      { feature: "Some domain support" },
      { feature: "Some editing" },
      { feature: "12 / 5 support" },
    ],
  },
];
export const standard = [
  {
    productIdMonthly: "prod_NhwJxnnZ4DyBdR",
    productIdYearly: "prod_NlzwYzuJtyIbkx",
    name: "Standard Plan",
    description: `Access all
    <span class="text-dark fw-medium">standard courses, workshops, and mobile apps.</span>
    Renewed monthly or one time payment yearly.`,
    monthlyPriceValue: "2999",
    yearlyPriceValue: "29999",
    monthlyPrice: 29.99,
    yearlyPrice: 299.99,
    featureHeading: "Individual features, plus:",
    features: [
      { feature: "Offline viewing" },
      { feature: `<span class="fw-bold text-dark">Offline </span>projects` },
      { feature: `<span class="fw-bold text-dark">Unlimited </span>storage` },
      { feature: "Custom domain support" },
      { feature: "Bulk editing" },
      { feature: "24 / 7 support" },
    ],
  },
];
export const premium = [
  {
    productIdMonthly: "prod_NhwLzwxD8xGS8F",
    productIdYearly: "prod_Nlztb888hWlHx2",
    name: "Premium Plan",
    description: `Access all
    <span class="text-dark fw-medium">premium courses, workshops, and mobile apps.</span>
    Renewed monthly or one time payment yearly.`,
    monthlyPriceValue: "4999",
    yearlyPriceValue: "49999",
    monthlyPrice: 49.99,
    yearlyPrice: 499.99,
    featureHeading: "All products, plus:",
    features: [
      { feature: "Offline viewing" },
      { feature: `<span class="fw-bold text-dark">Offline </span>projects` },
      { feature: `<span class="fw-bold text-dark">Unlimited </span>storage` },
      { feature: "All domain support" },
      { feature: "Bulk editing" },
      { feature: "24 / 7 support" },
    ],
  },
];

export const SubscriptionDetails = [...essential, ...standard, ...premium];

export default SubscriptionDetails;
