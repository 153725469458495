import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import "react-bootstrap";

const LanguageDropdown = ({ setLanguage }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentLanguage, setCurrentLanguage] = useState("English");

  const handleLanguageChange = (languageId, pathExtension, languageLabel) => {
    setLanguage(languageId);
    setCurrentLanguage(languageLabel);

    if (languageId === 3) {
      const newPathname = location.pathname.replace(/^\/(es\/)?/, "");
      navigate(`/${newPathname}`);
    } else {
      const newPathname = location.pathname.replace(/^\/(es\/)?/, "");
      navigate(`/${pathExtension}${newPathname}`);
    }
  };

  return (
    <div className="nav-item show dropdown d-flex align-items-center">
      <a className="nav-link show dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="true">
        {currentLanguage}
      </a>
      <div className="dropdown-menu show" data-bs-popper="static">
        <a className="dropdown-item" onClick={() => handleLanguageChange(3, "", "English")}>
          English
        </a>
        <a className="dropdown-item" onClick={() => handleLanguageChange(2, "es/", "Spanish")}>
          Spanish
        </a>
      </div>
    </div>
  );
};

LanguageDropdown.propTypes = {
  setLanguage: PropTypes.func.isRequired,
};

export default LanguageDropdown;
