import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Nav, Dropdown, Image } from "react-bootstrap";
import * as Icon from "react-feather";
import Avatar1 from "assets/images/avatar/anonymous_profile.png";
import PropTypes from "prop-types";
import userService from "services/userService";

const UserSettingsDropdown = (props) => {
  const navigate = useNavigate();

  const onSignOutHandler = () => {
    userService.logoutUser().then(onLogoutSuccess);
  };

  const onLogoutSuccess = () => {
    navigate("/", { state: { login: false } });
  };

  const onSelectProfile = () => {
    navigate("/profile");
  };

  const toSubscriptionPage = () => {
    navigate("/subscription/my-subscriptions");
  };

  return (
    <Dropdown as={Nav.Item}>
      <Dropdown.Toggle
        as={Nav.Link}
        bsPrefix="dt"
        className="rounded-circle border-bottom-0"
        id="dropdownUser"
      >
        <div className="avatar avatar-md avatar-indicators avatar-online">
          <Image
            alt="avatar"
            src={props.currentUser?.avatarUrl || Avatar1}
            className="rounded-circle"
          />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="dashboard-dropdown dropdown-menu-end mt-4 py-0"
        aria-labelledby="dropdownUser"
        align="end"
      >
        <Dropdown.Item className="mt-3">
          <div className="d-flex">
            <div className="avatar avatar-md avatar-indicators avatar-online">
              <Image
                alt="avatar"
                src={props.currentUser?.avatarUrl || Avatar1}
                className="rounded-circle"
              />
            </div>
            <div className="ms-3 lh-1">
              <h5 className="mb-1">{props.currentUser?.name}</h5>
              <p className="mb-0 text-muted">{props.currentUser?.email}</p>
            </div>
          </div>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="2" onClick={onSelectProfile}>
          <Icon.User className="me-2 py-1 text-secondary" />
          Profile
        </Dropdown.Item>
        <Dropdown.Item eventKey="3" onClick={toSubscriptionPage}>
          <Icon.Star className="me-2 py-1 text-secondary" />
          Subscription
        </Dropdown.Item>
        <Dropdown.Item>
          <Link to="/profile/settings">
            <Icon.Settings className="me-2 py-1 text-secondary" />
          </Link>
          Settings
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item className="mb-3" onClick={onSignOutHandler}>
          <Icon.Power className="me-2 py-1 text-secondary" /> Sign Out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

UserSettingsDropdown.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    avatarUrl: PropTypes.string,
    email: PropTypes.string,
    tenantId: PropTypes.number,
  }),
};

export default UserSettingsDropdown;
