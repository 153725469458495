import axios from "axios";
import {
  onGlobalError,
  onGlobalSuccess,
  API_HOST_PREFIX,
} from "./serviceHelpers";

const endpoint = `${API_HOST_PREFIX}/api/translations/pages/section/pagetranslation`;

const getByLanguageId = (linkName = "landing", languageId) => {
  const config = {
    method: "GET",
    url: `${endpoint}/${languageId}`,
    params: { linkName },
    withCredentials: true,
    crossdomain: true,
    headers: { "Content-Type": "application/json" },
  };
  return axios(config).then(onGlobalSuccess).catch(onGlobalError);
};

export default getByLanguageId;
