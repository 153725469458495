import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";
import NavbarVertical from "./NavbarVertical";
import HeaderDefault from "./HeaderDefault";
//import Footer from "../marketing/footers/Footer";

const DashboardIndex = (props) => {
  const { children, className, isOverflowHidden } = props;
  const isBigScreen = useMediaQuery({ query: "(min-width: 1224px)" });
  const [isMenuOpened, setShowMenu] = useState(isBigScreen ? true : false);

  const ToggleMenu = () => {
    return setShowMenu(!isMenuOpened);
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#f5f4f8";
  });

  return (
    <div
      id="db-wrapper"
      className={`${isOverflowHidden ? "chat-layout" : ""} ${
        isMenuOpened ? "" : "toggled"
      }`}
    >
      <div className="navbar-vertical navbar">
        <NavbarVertical
          isMenuOpened={isMenuOpened}
          onClick={(value) => setShowMenu(value)}
          role={props.currentUser.roles[0]}
        />
      </div>
      <div id="page-content">
        <div className="header">
          <HeaderDefault
            data={{
              isMenuOpened: isMenuOpened,
              SidebarToggleMenu: ToggleMenu,
            }}
            currentUser={props.currentUser}
          />
        </div>
        <div className={`container-fluid ${className ? className : "p-4"}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

DashboardIndex.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  isOverflowHidden: PropTypes.bool,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    avatarUrl: PropTypes.string,
    email: PropTypes.string,
    conferenceId: PropTypes.number,
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default DashboardIndex;
