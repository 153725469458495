import { lazy } from "react";
const Landing = lazy(() => import("../components/landing/Landing"));
const SignUp = lazy(() =>
  import("../components/dashboard/autentication/SignUp")
);
const SignIn = lazy(() =>
  import("../components/dashboard/autentication/SignIn")
);
const ContactUs = lazy(() => import("../components/contactus/ContactUs"));
const AboutUsMemberList = lazy(() =>
  import("../components/aboutus/AboutUsMemberList")
);
const HelpCenter = lazy(() => import("../helpcenter/HelpCenter"));
const SiteReferences = lazy(() =>
  import("../components/sitereferences/SiteReferences")
);
const Faq = lazy(() => import("../components/faqs/Faq"));

const VerifyEmail = lazy(() =>
  import("components/dashboard/autentication/VerifyEmail")
);
const ForgetPassword = lazy(() =>
  import("components/dashboard/autentication/ForgetPassword")
);
const ChangePassword = lazy(() =>
  import("components/dashboard/autentication/ChangePassword")
);

const PrivacyPolicy = lazy(() =>
  import("../components/privacypolicy/PrivacyPolicy")
);
const CookiePolicy = lazy(() =>
  import("../components/cookiepolicy/CookiePolicy")
);

const Podcast = lazy(() => import("../components/podcasts/Podcast"));

const ProgressTracker = lazy(() =>
  import("../components/progresstracker/ProgressTracker")
);

const routes = [
  {
    path: "/",
    name: "Landing",
    exact: true,
    element: Landing,
    roles: [],
    isAnonymous: true,
  },
  {
    path: "/es",
    name: "Landing",
    exact: true,
    element: Landing,
    roles: [],
    isAnonymous: true,
  },

  {
    path: "/authentication/sign-up",
    name: "SignUp",
    exact: true,
    element: SignUp,
    roles: [],
    isAnonymous: true,
  },
  {
    path: "/authentication/sign-in",
    name: "SignIn",
    exact: true,
    element: SignIn,
    roles: [],
    isAnonymous: true,
  },
  {
    path: "/authentication/verifyemail",
    name: "VerifyEmail",
    exact: true,
    element: VerifyEmail,
    roles: [],
    isAnonymous: true,
  },
  {
    path: "/authentication/changepassword",
    name: "ChangePassword",
    exact: true,
    element: ChangePassword,
    roles: [],
    isAnonymous: true,
  },
  {
    path: "/authentication/forget-password",
    name: "ForgetPassword",
    exact: true,
    element: ForgetPassword,
    roles: [],
    isAnonymous: true,
  },
  {
    path: "/authentication/registrationinvitation",
    name: "RegistrationInvitation",
    exact: true,
    element: SignUp,
    roles: [],
    isAnonymous: true,
  },
  {
    path: "/contactus",
    name: "ContactUs",
    exact: true,
    element: ContactUs,
    roles: [],
    isAnonymous: true,
  },
  {
    path: "/es/contactus",
    name: "ContactUs",
    exact: true,
    element: ContactUs,
    roles: [],
    isAnonymous: true,
  },
  {
    path: "/helpandsupport",
    name: "helpandsupport",
    exact: true,
    element: HelpCenter,
    roles: [],
    isAnonymous: true,
  },
  {
    path: "/sitereferences",
    name: "sitereferences",
    exact: true,
    element: SiteReferences,
    roles: [],
    isAnonymous: true,
  },
  {
    path: "/aboutusmemberlist",
    name: "aboutusmemberlist",
    exact: true,
    element: AboutUsMemberList,
    roles: [],
    isAnonymous: true,
  },
  {
    path: "/es/aboutusmemberlist",
    name: "aboutusmemberlist",
    exact: true,
    element: AboutUsMemberList,
    roles: [],
    isAnonymous: true,
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicy",
    exact: true,
    element: PrivacyPolicy,
    roles: [],
    isAnonymous: true,
  },
  {
    path: "/cookiepolicy",
    name: "CookiePolicy",
    exact: true,
    element: CookiePolicy,
    roles: [],
    isAnonymous: true,
  },
  {
    path: "/podcasts",
    name: "Podcast",
    exact: true,
    element: Podcast,
    roles: [],
    isAnonymous: true,
  },
  {
    path: "/progresstracker",
    name: "ProgressTracker",
    exact: true,
    element: ProgressTracker,
    roles: [],
    isAnonymous: true,
  },
];

const faq = [
  {
    path: "/faqs",
    name: "faqList",
    element: Faq,
    roles: [],
    exact: true,
    isAnonymous: true,
  },
];

const allRoutes = [...routes, ...faq];

export default allRoutes;
