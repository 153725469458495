import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Image, Container, ListGroup } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
// import Icon from "@mdi/react";
// import { mdiFacebook, mdiTwitter, mdiInstagram } from "@mdi/js";
import FooterLogo from "assets/images/brand/logo/logo_white_footer.png";
import { SubscriptionDetails } from "../../../components/stripe/subscriptionDetails";
import PropTypes from "prop-types";

const FooterWithLinks = (props) => {
  const currentUser = props.currentUser;

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const stateForTransport = {
    type: "SUBSCRIPTION_VIEW",
    payload: SubscriptionDetails,
  };
  return (
    <Fragment>
      <div className="pt-lg-10 pt-5 footer bg-dark">
        <Container>
          <Row>
            <Col lg={4} md={6} sm={12}>
              {!isMobile && (
                <div className="mb-4 px-3">
                  <Image src={FooterLogo} alt="" />
                  <div className="mt-4">
                    <p className="text-light text-muted">
                      Assign Ref: Assign | Match | Rank | Get paid, all in one
                      convenient place{" "}
                    </p>
                    {/* <div className="fs-4 mt-4">
                    <Link
                      to="#"
                      className="mdi mdi-facebook me-2 text-light text-muted"
                    >
                      <Icon path={mdiFacebook} size={0.7} />
                    </Link>
                    <Link
                      to="#"
                      className="mdi mdi-twitter me-2 text-light text-muted"
                    >
                      <Icon path={mdiTwitter} size={0.7} />
                    </Link>
                    <Link
                      to="#"
                      className="mdi mdi-instagram text-light text-muted  "
                    >
                      <Icon path={mdiInstagram} size={0.7} />
                    </Link>
                  </div> */}
                  </div>
                </div>
              )}
            </Col>
            <Col lg={{ span: 2, offset: 1 }} md={3} sm={6}>
              <div className="mb-4 px-3">
                <h3 className="fw-bold mb-3 text-light text-muted">Company</h3>
                <ListGroup
                  as="ul"
                  bsPrefix="list-unstyled"
                  className="nav nav-footer flex-column nav-x-0"
                >
                  <ListGroup.Item as="li" bsPrefix=" ">
                    <Link to="/aboutusmemberlist" className="nav-link">
                      About Us
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" ">
                    {currentUser.id === 0 ? (
                      <Link
                        to={"/authentication/sign-in"}
                        state={{ stateForTransport }}
                        className="nav-link"
                      >
                        Pricing
                      </Link>
                    ) : (
                      <Link to={"/subscriptions"} className="nav-link">
                        Pricing
                      </Link>
                    )}
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" ">
                    <Link to="/podcasts" className="nav-link">
                      Podcasts
                    </Link>
                  </ListGroup.Item>
                  {/*NOT IN USE*/}
                  {/* <ListGroup.Item as="li" bsPrefix=" ">
                    <Link to="#" className="nav-link">
                      Careers
                    </Link>
                  </ListGroup.Item> */}
                  <ListGroup.Item as="li" bsPrefix=" ">
                    <Link to="contactus" className="nav-link">
                      Contact Us
                    </Link>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </Col>
            <Col lg={2} md={3} sm={6}>
              <div className="mb-4 px-3">
                <h3 className="fw-bold mb-3 text-light text-muted">Support</h3>
                <ListGroup
                  as="ul"
                  bsPrefix="list-unstyled"
                  className="nav nav-footer flex-column nav-x-0"
                >
                  <ListGroup.Item as="li" bsPrefix=" ">
                    <Link to="/helpandsupport" className="nav-link">
                      Help and Support
                    </Link>
                  </ListGroup.Item>
                  {/*NOT IN USE*/}
                  {/* <ListGroup.Item as="li" bsPrefix=" ">
                    <Link to="#" className="nav-link">
                      Become Referee Instructor
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" ">
                    <Link to="#" className="nav-link">
                      Get the app
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" ">
                    <Link to="#" className="nav-link">
                      Tutorial
                    </Link>
                  </ListGroup.Item> */}
                </ListGroup>
              </div>
            </Col>
            <Col lg={3} md={12} sm={12}>
              <div className="mb-4 px-3">
                <h3 className="fw-bold mb-3 text-light text-muted">
                  Contact us
                </h3>
                <p className=" text-light text-muted">
                  339 McDermott Points Hettingerhaven, NV 15283
                </p>
                <p className="mb-1 text-light text-muted">
                  Email:{" "}
                  <Link to="#" className="text-light text-muted">
                    support@assignref.com
                  </Link>
                </p>
                <p className="text-light text-muted">
                  Phone:{" "}
                  <span className="text-dark fw-semi-bold text-light text-muted">
                    (808) 555 555 5555
                  </span>
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center g-0 border-top py-2 mt-6 text-light text-muted px-3">
            <Col lg={4} md={5} sm={12}>
              <span>© 2023 AssignRef. All Rights Reserved</span>
            </Col>
            <Col
              lg={8}
              md={7}
              sm={12}
              className="d-md-flex justify-content-end"
            >
              <nav className="nav nav-footer">
                <Link className="nav-link ps-0" to="/privacypolicy">
                  Privacy Policy
                </Link>
                <Link className="nav-link px-2 px-md-3" to="/cookiepolicy">
                  Cookie Notice{" "}
                </Link>
                {/*NOT IN USE*/}
                {/* <Link className="nav-link d-none d-lg-block" to="#">
                  Assign Ref Does Not Share Your Personal Information{" "}
                </Link>
                <Link className="nav-link" to="#">
                  Terms of Use
                </Link> */}
              </nav>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

FooterWithLinks.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default FooterWithLinks;
