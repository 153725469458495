import React, { Fragment, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Image, Navbar, Nav, Container } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Logo from "assets/images/brand/logo/logo_white_sm.png";
//import NotificationsDropdown from "layouts/dashboard/NotificationsDropdown";
import LanguageDropdown from "components/landing/LanguageDropDown";
import NavbarDefaultRoutes from "./NavbarDefaultRoutes";
import NavDropdownMain from "layouts/marketing/navbars/NavDropdownMain";
import ConferencesDropdown from "layouts/dashboard/ConferencesDropdown";
import UserSettingsDropdown from "layouts/dashboard/UserSettingsDropdown";
import sabioDebug from "sabio-debug";
import LanguageContext from "../../../components/translations/LanguageContext";

const _logger = sabioDebug.extend("Navbar Default");

const NavbarDefault = ({ headerstyle, currentUser }) => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  _logger(currentUser, LanguageDropdown);

  const [expandedMenu, setExpandedMenu] = useState(false);
  const languageSet = useContext(LanguageContext);
  _logger(languageSet);

  const onSetMenu = (menu) => {
    setExpandedMenu(menu);
  };

  const QuickMenu = () => {
    return (
      <Fragment>
        {/* <NotificationsDropdown /> */}
        <ConferencesDropdown currentUser={currentUser} />
        <UserSettingsDropdown currentUser={currentUser} />
      </Fragment>
    );
  };
  return (
    <Fragment>
      <Navbar onToggle={onSetMenu} expanded={expandedMenu} expand="lg" className={`${"bg-dark"} navbar p-2 ${headerstyle === "dark" ? "navbar-dark bg-dark" : "navbar-default py-2"}`}>
        <Container fluid className="px-0 ps-2">
          <Navbar.Brand as={Link} to="/">
            <Image src={Logo} alt="" style={{ height: "60px" }} />
          </Navbar.Brand>
          <div className={`navbar-nav navbar-right-wrap ms-auto d-lg-none nav-top-wrap ${currentUser?.isLoggedIn ? (isDesktop || isLaptop ? "d-none" : "d-flex") : "d-none"}`}>
            <QuickMenu />
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="icon-bar top-bar mt-0 bg-white"></span>
            <span className="icon-bar middle-bar bg-white"></span>
            <span className="icon-bar bottom-bar bg-white"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              {NavbarDefaultRoutes.map((item, index) => {
                if (item.children === undefined) {
                  return (
                    <Nav.Link key={index} as={Link} to={item.link}>
                      {item.menuitem}
                    </Nav.Link>
                  );
                } else {
                  if (item.roles?.some((role) => role === currentUser.roles[0]) || item.roles?.some((role) => role === "Public")) {
                    return <NavDropdownMain item={item} key={index} onClick={onSetMenu} role={currentUser.roles[0]} />;
                  }
                }
              })}
            </Nav>

            {/* <Form className="mt-3 mt-lg-0 ms-lg-3 d-flex align-items-center">
              <span className="position-absolute ps-3 search-icon">
              <i className="fe fe-search"></i>
              </span>
              <Form.Control
              type="Search"
              id="formSearch"
              className="ps-6"
              placeholder="Search Courses"
              />
            </Form> */}
            {/* Right side quick / shortcut menu  */}

            <Nav className="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap">
              <span className={`ms-auto mt-3 mt-lg-0  ${currentUser?.isLoggedIn ? "d-none" : ""}`}>
                <Nav.Link as={Link} to="/authentication/sign-in" bsPrefix="btn" className="btn btn-white shadow-sm me-2">
                  Sign In
                </Nav.Link>
                <Nav.Link as={Link} to="/authentication/sign-up" bsPrefix="btn" className="btn btn-primary shadow-sm border border-light">
                  Sign Up
                </Nav.Link>
              </span>

              <span className={`${currentUser?.isLoggedIn ? (isDesktop || isLaptop ? "d-flex" : "d-none") : "d-none"}`}>
                <LanguageDropdown setLanguage={languageSet.setLanguage} />
                <QuickMenu />
              </span>
            </Nav>
            {/* end of right side quick / shortcut menu  */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Fragment>
  );
};

NavbarDefault.defaultProps = {
  headerstyle: "navbar-default",
};

NavbarDefault.propTypes = {
  headerstyle: PropTypes.string,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    avatarUrl: PropTypes.string,
    email: PropTypes.string,
    conferenceId: PropTypes.number,
    isLoggedIn: PropTypes.bool,
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default NavbarDefault;
